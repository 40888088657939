import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { RegisterYourSpot } from './RegisterYourSpot';
var BookMySpot = function (_a) {
    var countdown = _a.countdown, onSubmit = _a.onSubmit;
    var formaterTime = useState(new Date())[0];
    var _b = useState(''), hours = _b[0], setHours = _b[1];
    var _c = useState(''), minuts = _c[0], setMinuts = _c[1];
    var _d = useState(''), secs = _d[0], setSecs = _d[1];
    var _e = useState(false), registrationModalShow = _e[0], setRegistrationModalShow = _e[1];
    var today = new Date();
    useEffect(function () {
        var time = countdown.split(':');
        formaterTime.setHours(today.getHours() + Number(time[0]));
        formaterTime.setMinutes(today.getMinutes() + Number(time[1]));
        formaterTime.setSeconds(today.getSeconds() + Number(time[2]));
    }, []);
    useEffect(function () {
        setTimeout(function () {
            var diff = (formaterTime === null || formaterTime === void 0 ? void 0 : formaterTime.getTime()) - today.getTime();
            var hh = Math.floor(diff / 1000 / 60 / 60);
            diff -= hh * 1000 * 60 * 60;
            var mm = Math.floor(diff / 1000 / 60);
            diff -= mm * 1000 * 60;
            var ss = Math.floor(diff / 1000);
            setHours(hh.toString().padStart(2, '0'));
            setMinuts(mm.toString().padStart(2, '0'));
            setSecs(ss.toString().padStart(2, '0'));
        }, 1000);
    }, [secs]);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'tw-relative tw-rounded tw-px-5 tw-py-8 tw-text-white tw-w-96 tw-bg-[#4BBCFF]' },
            React.createElement("div", { className: 'tw-text-center' },
                React.createElement("div", { className: 'tw-text-3xl tw-font-semibold' }, "Reserve Your 24 Hours Pass!"),
                React.createElement("p", null, "Act-Fast You Don't Want To Miss This!")),
            React.createElement("div", { className: 'tw-my-5' },
                React.createElement("div", { className: 'tw-flex tw-justify-center' },
                    React.createElement("div", { className: 'tw-m-2' },
                        React.createElement("div", { className: 'tw-border tw-rounded tw-p-4 tw-col' },
                            React.createElement("p", { className: 'tw-text-4xl tw-font-semibold' }, hours)),
                        React.createElement("p", null, "Hours")),
                    React.createElement("div", { className: 'tw-m-2' },
                        React.createElement("div", { className: 'tw-border tw-rounded tw-p-4 tw-col' },
                            React.createElement("p", { className: 'tw-text-4xl tw-font-semibold' }, minuts)),
                        React.createElement("p", null, "Minuts")),
                    React.createElement("div", { className: 'tw-m-2' },
                        React.createElement("div", { className: 'tw-border tw-rounded tw-p-4 tw-col' },
                            React.createElement("p", { className: 'tw-text-4xl tw-font-semibold' }, secs)),
                        React.createElement("p", null, "Seconds"))),
                React.createElement("div", { className: 'tw-text-center tw-mt-5' },
                    React.createElement(Button, { variant: 'warning', onClick: function () {
                            setRegistrationModalShow(true);
                        } },
                        React.createElement("b", { className: 'tw-p-8 tw-text-xl' }, "Watch Training"))))),
        registrationModalShow && (React.createElement(RegisterYourSpot, { onSubmit: onSubmit, onClickOutside: function () {
                setRegistrationModalShow(false);
            } }))));
};
export default BookMySpot;
