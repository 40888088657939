import { __assign } from "tslib";
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import './Table.css';
import EmptyData from './EmptyData';
var DEFAULT_PROFILE_PICTURE = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-profile-picture.png';
var Table = function (_a) {
    var _b = _a.sortByKey, sortByKey = _b === void 0 ? 'Name' : _b, _c = _a.hiddenColumns, hiddenColumns = _c === void 0 ? [] : _c, _d = _a.tableData, tableData = _d === void 0 ? [] : _d, _e = _a.tableColumns, tableColumns = _e === void 0 ? [] : _e, _f = _a.noDataIcon, noDataIcon = _f === void 0 ? '' : _f, handleClickRow = _a.handleClickRow, _g = _a.sortByOrder, sortByOrder = _g === void 0 ? 'desc' : _g;
    var sortees = React.useMemo(function () { return [
        {
            id: sortByKey,
            desc: Boolean(sortByOrder === 'desc'),
        },
    ]; }, []);
    var data = React.useMemo(function () { return tableData; }, []);
    var columns = React.useMemo(function () { return tableColumns; }, []);
    var table = useTable({ columns: columns, data: data, initialState: { sortBy: sortees, hiddenColumns: hiddenColumns } }, useSortBy);
    var getTableProps = table.getTableProps, getTableBodyProps = table.getTableBodyProps, headerGroups = table.headerGroups, rows = table.rows, prepareRow = table.prepareRow;
    return (React.createElement("div", { className: 'container' }, data.length > 0 ? (React.createElement("table", __assign({}, getTableProps(), { className: "tw-border-collapse tw-border tw-w-full ".concat(data.length < 1 && 'tw-bg-light', " ") }),
        React.createElement("thead", null, headerGroups.map(function (headerGroup, i) { return (React.createElement("tr", __assign({ key: i }, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column, index) { return (React.createElement("th", __assign({ key: index, style: { verticalAlign: 'center' }, className: 'tw-text-left tw-p-2 md:tw-p-4 tw-border' }, column.getHeaderProps(column.getSortByToggleProps())),
            column.render('label'),
            column.sortable && (React.createElement("span", { className: 'tw-ml-1' }, column.isSorted ? (React.createElement(React.Fragment, null, column.isSortedDesc ? (React.createElement("i", { className: 'fas fa-sort-down' })) : (React.createElement("i", { className: 'fas fa-sort-up' })))) : (React.createElement("i", { className: 'fas fa-sort', style: { opacity: '.25' } })))))); }))); })),
        React.createElement("tbody", __assign({}, getTableBodyProps()), rows.map(function (row, index) {
            prepareRow(row);
            return (React.createElement("tr", __assign({ key: index }, row.getRowProps(), { className: 'row-clickable', onClick: handleClickRow }), row.cells.map(function (cell, i) {
                return (React.createElement("td", __assign({ key: i, className: 'tw-p-2 md:tw-p-4 tw-border' }, cell.getCellProps()), cell.render('Cell')));
            })));
        })))) : (React.createElement(EmptyData, { text: 'No Users', iconClassName: noDataIcon }))));
};
export default Table;
