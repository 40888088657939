import React, { useRef } from 'react';
import { FaCircleXmark, FaMagnifyingGlass } from 'react-icons/fa6';
import { debounceEvent } from '../../utils';
import Button from '../Button/Button';
var SearchBox = function (_a) {
    var onSubmit = _a.onSubmit, onClear = _a.onClear, _b = _a.name, name = _b === void 0 ? 'searchValueInput' : _b, _c = _a.value, value = _c === void 0 ? '' : _c, _d = _a.loading, loading = _d === void 0 ? false : _d, _e = _a.placeholder, placeholder = _e === void 0 ? 'Search...' : _e, _f = _a.autoFocus, autoFocus = _f === void 0 ? false : _f, _g = _a.className, className = _g === void 0 ? '' : _g, children = _a.children;
    var _h = React.useState(value || ''), searchInputValue = _h[0], setSearchInputValue = _h[1];
    var inputRef = useRef(null);
    var clearSearchResults = function () {
        if (onClear && typeof onClear === 'function') {
            onClear();
            setSearchInputValue('');
        }
        onSubmit('');
        setSearchInputValue('');
        inputRef.current.focus();
    };
    var optimizedOnSubmit = React.useCallback(debounceEvent(onSubmit, 500), []);
    var onChange = function (e) {
        var inputValue = e.target.value;
        setSearchInputValue(inputValue);
        optimizedOnSubmit(inputValue);
    };
    return (React.createElement("div", { className: "tw-bg-white ".concat(className) },
        React.createElement("form", { onSubmit: function (event) {
                event.preventDefault();
                onSubmit(searchInputValue);
            } },
            React.createElement("div", { className: 'tw-flex' },
                React.createElement("div", { className: 'tw-flex tw-items-center tw-w-100' },
                    React.createElement("div", { className: 'tw-relative' },
                        React.createElement("input", { type: 'text', className: 'tw-rounded-l-lg', name: name, value: searchInputValue, onChange: onChange, placeholder: placeholder, autoFocus: autoFocus, ref: inputRef }),
                        searchInputValue && (React.createElement(Button, { type: 'button', role: 'button', variant: 'link', className: 'tw-absolute tw-bottom-0 tw-top-0 tw-right-0 tw-z-10', onClick: clearSearchResults, title: 'Reset Search' },
                            React.createElement(FaCircleXmark, { className: 'tw-text-gray-500 hover:tw-text-gray-800 tw-text-base' }))),
                        React.createElement(Button, { className: 'tw-absolute tw-bottom-0 tw-top-0 tw-left-[100%] tw-rounded-r-lg tw-rounded-l-none tw-px-2', size: 'sm', variant: searchInputValue ? 'primary' : 'secondary', disabled: loading || !searchInputValue, title: 'Search', role: 'button' },
                            React.createElement(FaMagnifyingGlass, { className: 'tw-text-xl' })))))),
        children || null));
};
export default SearchBox;
