import { __awaiter, __generator } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { AudioSourceState, DecoderState, useSpeechContext } from '@speechly/react-client';
import PubSub from 'pubsub-js';
import { PushToTalkButtonContainer } from './PushToTalkButtonContainer';
import { SpeechlyUiEvents } from './constants';
export var MicButton = function (_a) {
    var _b = _a.powerOn, powerOn = _b === void 0 ? false : _b, _c = _a.hint, hint = _c === void 0 ? 'Hold to talk' : _c, backgroundColor = _a.backgroundColor, _d = _a.placement, placement = _d === void 0 ? 'bottom' : _d, _e = _a.hide, hide = _e === void 0 ? false : _e;
    var _f = useSpeechContext(), client = _f.client, clientState = _f.clientState, microphoneState = _f.microphoneState, attachMicrophone = _f.attachMicrophone, start = _f.start, stop = _f.stop, segment = _f.segment;
    var _g = useState(false), loaded = _g[0], setLoaded = _g[1];
    var _h = useState(DecoderState.Disconnected), icon = _h[0], setIcon = _h[1];
    var _j = useState(true), showHint = _j[0], setShowHint = _j[1];
    var _k = useState(powerOn === true), usePermissionPriming = _k[0], setUsePermissionPriming = _k[1];
    var buttonStateRef = useRef({
        tapListenActive: false,
        wasListening: false,
        holdListenActive: false,
        tapListenTimeout: null,
        tangentPressPromise: null,
    });
    var buttonRef = useRef();
    var TAP_TRESHOLD_MS = 600;
    var PERMISSION_PRE_GRANTED_TRESHOLD_MS = 1500;
    var TAP_TO_TALK_TIME = 8000;
    var SILENCE_TO_HANGUP_TIME = 1000;
    // make stateRef always have the current count
    // your "fixed" callbacks can refer to this object whenever
    // they need the current value.  Note: the callbacks will not
    // be reactive - they will not re-run the instant state changes,
    // but they *will* see the current value whenever they do run
    var clientStateRef = useRef(clientState);
    var microphoneStateRef = useRef(microphoneState);
    var loadHoldableButtonScript = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var existingScript, script;
        return __generator(this, function (_a) {
            existingScript = document.getElementById('holdable-button-script');
            if (!existingScript) {
                script = document.createElement('script');
                script.src = 'https://unpkg.com/@speechly/browser-ui/core/holdable-button.js';
                script.id = 'holdable-button-script';
                document.body.appendChild(script);
                script.onload = function () {
                    if (callback)
                        callback();
                };
            }
            if (existingScript && callback)
                callback();
            return [2 /*return*/];
        });
    }); };
    var loadCallOutScript = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var existingScript, script;
        return __generator(this, function (_a) {
            existingScript = document.getElementById('call-out-script');
            if (!existingScript) {
                script = document.createElement('script');
                script.src = 'https://unpkg.com/@speechly/browser-ui/core/call-out.js';
                script.id = 'call-out-script';
                document.body.appendChild(script);
                script.onload = function () {
                    if (callback)
                        callback();
                };
            }
            if (existingScript && callback)
                callback();
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        ;
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var loadSpeechButton, loadCallOut;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadSpeechButton = loadHoldableButtonScript(setLoaded(false));
                        loadCallOut = loadCallOutScript(setLoaded(false));
                        return [4 /*yield*/, Promise.all([loadSpeechButton, loadCallOut])];
                    case 1:
                        _a.sent();
                        setLoaded(true);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    // Use browser API only after mount to play nice with Next.js SSR
    useEffect(function () {
        if (powerOn === 'auto') {
            setUsePermissionPriming(localStorage.getItem("SpeechlyFirstConnect" /* LocalStorageKeys.SpeechlyFirstConnect */) === null);
        }
    }, [powerOn]);
    useEffect(function () {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) {
            var button = buttonRef.current;
            button.onholdstart = tangentPressAction;
            button.onholdend = tangentReleaseAction;
        }
    });
    useEffect(function () {
        clientStateRef.current = clientState;
        microphoneStateRef.current = microphoneState;
        // Change button appearance according to Speechly states
        switch (microphoneState) {
            case AudioSourceState.NoAudioConsent:
            case AudioSourceState.NoBrowserSupport:
                setIcon(microphoneState);
                break;
            default:
                setIcon(clientState);
                break;
        }
        if (clientState >= DecoderState.Connected && microphoneState === AudioSourceState.Started) {
            setUsePermissionPriming(false);
            // Set connect made
            if (localStorage.getItem("SpeechlyFirstConnect" /* LocalStorageKeys.SpeechlyFirstConnect */) === null) {
                localStorage.setItem("SpeechlyFirstConnect" /* LocalStorageKeys.SpeechlyFirstConnect */, String(Date.now()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientState, microphoneState]);
    var tangentPressAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!client) {
                throw Error('No Speechly client (are you using Speechly in non-browser environment?)');
            }
            buttonStateRef.current.tangentPressPromise = (function () { return __awaiter(void 0, void 0, void 0, function () {
                var initStartTime, err_1, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            PubSub.publish(SpeechlyUiEvents.TangentPress, { state: clientStateRef.current });
                            window.postMessage({
                                type: 'holdstart',
                                state: clientStateRef.current,
                                audioSourceState: microphoneStateRef.current,
                            }, '*');
                            setShowHint(false);
                            if (!usePermissionPriming) return [3 /*break*/, 1];
                            window.postMessage({
                                type: "speechlypoweron" /* MessageType.speechlypoweron */,
                            }, '*');
                            return [3 /*break*/, 11];
                        case 1:
                            if (buttonStateRef.current.tapListenTimeout) {
                                window.clearTimeout(buttonStateRef.current.tapListenTimeout);
                                buttonStateRef.current.tapListenTimeout = null;
                            }
                            if (!(clientStateRef.current >= DecoderState.Connected &&
                                microphoneStateRef.current === AudioSourceState.Started)) return [3 /*break*/, 2];
                            buttonStateRef.current.holdListenActive = true;
                            return [3 /*break*/, 7];
                        case 2:
                            initStartTime = Date.now();
                            _a.label = 3;
                        case 3:
                            _a.trys.push([3, 5, , 6]);
                            return [4 /*yield*/, attachMicrophone()];
                        case 4:
                            _a.sent();
                            return [3 /*break*/, 6];
                        case 5:
                            err_1 = _a.sent();
                            console.error('Error initializing Speechly', err_1);
                            return [3 /*break*/, 6];
                        case 6:
                            // Long init time suggests permission dialog --> prevent listening start
                            buttonStateRef.current.holdListenActive =
                                Date.now() - initStartTime < PERMISSION_PRE_GRANTED_TRESHOLD_MS;
                            _a.label = 7;
                        case 7:
                            if (!buttonStateRef.current.holdListenActive) return [3 /*break*/, 11];
                            buttonStateRef.current.wasListening = client.isActive();
                            if (!!client.isActive()) return [3 /*break*/, 11];
                            _a.label = 8;
                        case 8:
                            _a.trys.push([8, 10, , 11]);
                            return [4 /*yield*/, start()];
                        case 9:
                            _a.sent();
                            return [3 /*break*/, 11];
                        case 10:
                            err_2 = _a.sent();
                            console.error('Error while starting to record', err_2);
                            return [3 /*break*/, 11];
                        case 11: return [2 /*return*/];
                    }
                });
            }); })();
            return [2 /*return*/];
        });
    }); };
    var tangentReleaseAction = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: 
                // Ensure async tangentPress and Release are run in appropriate order
                return [4 /*yield*/, buttonStateRef.current.tangentPressPromise];
                case 1:
                    // Ensure async tangentPress and Release are run in appropriate order
                    _a.sent();
                    PubSub.publish(SpeechlyUiEvents.TangentRelease, {
                        state: clientStateRef.current,
                        timeMs: event.timeMs,
                    });
                    window.postMessage({ type: 'holdend' }, '*');
                    if (buttonStateRef.current.holdListenActive) {
                        buttonStateRef.current.holdListenActive = false;
                        if (event.timeMs < TAP_TRESHOLD_MS) {
                            // Tap: toggle listening on/off
                            if (buttonStateRef.current.wasListening) {
                                stopListening();
                            }
                            else {
                                // schedule "silence based stop"
                                setStopContextTimeout(TAP_TO_TALK_TIME);
                            }
                        }
                        else {
                            stopListening();
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var setStopContextTimeout = function (timeoutMs) {
        buttonStateRef.current.tapListenActive = true;
        if (buttonStateRef.current.tapListenTimeout) {
            window.clearTimeout(buttonStateRef.current.tapListenTimeout);
        }
        buttonStateRef.current.tapListenTimeout = window.setTimeout(function () {
            buttonStateRef.current.tapListenTimeout = null;
            stopListening();
        }, timeoutMs);
    };
    var stopListening = function () {
        buttonStateRef.current.tapListenActive = false;
        if (client === null || client === void 0 ? void 0 : client.isActive()) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                stop();
            }
            catch (err) {
                console.error('Error while stopping recording', err);
            }
        }
    };
    /**
     * Extend listening time if segment updates received
     */
    useEffect(function () {
        if (segment) {
            if (buttonStateRef.current.tapListenTimeout) {
                setStopContextTimeout(SILENCE_TO_HANGUP_TIME);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segment]);
    if (!loaded)
        return null;
    return (React.createElement("div", { "data-testid": 'mic-button', className: "tw-uppercase tw-text-white text-[100%] leading=[120%] font-['Saira Condensed']" },
        placement === 'bottom' && (React.createElement(PushToTalkButtonContainer, null,
            React.createElement("holdable-button", { ref: buttonRef, poweron: powerOn, icon: icon, gradientstop1: '#15e8b5', gradientstop2: '#4fa1f9', hide: hide ? 'true' : 'false' }),
            React.createElement("call-out", { show: showHint, backgroundcolor: backgroundColor }, hint))),
        placement !== 'bottom' && (React.createElement(React.Fragment, null,
            React.createElement("holdable-button", { ref: buttonRef, poweron: powerOn, icon: icon, gradientstop1: '#15e8b5', gradientstop2: '#4fa1f9', hide: hide ? 'true' : 'false' }),
            React.createElement("call-out", { show: showHint, backgroundcolor: backgroundColor }, hint)))));
};
