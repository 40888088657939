import React, { useState, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa6';
var Collapse = function (_a) {
    var _b = _a.id, id = _b === void 0 ? 'collapseExample' : _b, _c = _a.title, title = _c === void 0 ? 'No title' : _c, _d = _a.children, children = _d === void 0 ? 'No children' : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.ariaExpanded, ariaExpanded = _f === void 0 ? false : _f, _g = _a.p, p = _g === void 0 ? '4' : _g, badge = _a.badge;
    var _h = useState(ariaExpanded), showChildren = _h[0], setShowChildrens = _h[1];
    useEffect(function () {
        setShowChildrens(ariaExpanded);
    }, [ariaExpanded]);
    return (React.createElement("div", { key: id, className: "".concat(className, " tw-rounded tw-border tw-overflow-hidden") },
        React.createElement("div", { className: "tw-cursor-pointer tw-flex tw-items-center tw-justify-between padding-".concat(p, " tw-text-lg tw-bg-gray-50 hover:tw-bg-blue-50"), "aria-expanded": showChildren, onClick: function () { return setShowChildrens(function (prevState) { return !prevState; }); } },
            React.createElement("div", { className: 'tw-font-weight-bold tw-d-flex tw-align-items-center tw-pr-3' },
                React.createElement("span", null, title),
                "\u00A0",
                React.createElement(React.Fragment, null, badge === null || badge === void 0 ? void 0 : badge())),
            React.createElement("div", null, showChildren ? React.createElement(FaMinus, null) : React.createElement(FaPlus, null))),
        React.createElement("div", { "data-testid": 'collapse-children', className: "tw-border-t ".concat(showChildren ? 'tw-block' : 'tw-hidden'), id: id },
            React.createElement("div", { className: "padding-".concat(p) }, children))));
};
export default Collapse;
